/* 2.3 Font weight*/
/* 2.1 Font Size*/
/* 3.0 Heading Size*/
@-webkit-keyframes preloadAnim {
  0% {
    background-position: -1200px 0;
  }
  100% {
    background-position: 1200px 0;
  }
}
@keyframes preloadAnim {
  0% {
    background-position: -1200px 0;
  }
  100% {
    background-position: 1200px 0;
  }
}
.text-bold {
  font-weight: bold;
}

.h1 {
  font-size: 32px;
}

.h2 {
  font-size: 20px;
}

.color-primary {
  color: #d83f43;
}
.color-gray1 {
  color: #b3b3b3;
}
.color-gray2 {
  color: #cccccc;
}
.color-gray3 {
  color: #e6e6e6;
}
.color-gray4 {
  color: #f4f4f4;
}

.pagination {
  justify-content: flex-end;
}

.page-link {
  color: #d83f43 !important;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff !important;
  background-color: #d83f43 !important;
  border-color: #d83f43 !important;
}

.modalBody {
  padding: 1rem;
  text-align: center;
  font-size: 24px;
}
.modalBody-title {
  text-align: center;
  margin-bottom: 1rem;
}

.nav-pills .nav-link {
  background: 0 0;
  border: 0;
  border-radius: 0.25rem;
  color: #4d4d4d;
  width: 12rem !important;
  text-align: center;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #d83f43 !important;
  background: white !important;
  font-weight: 600;
  border-bottom: 2px solid;
  width: 9rem;
  border-radius: 0;
  text-align: center;
}

.nav-link:hover {
  color: #4d4d4d !important;
  cursor: pointer;
}

.cpntr {
  cursor: pointer;
}

svg:not(:root) {
  overflow: unset;
}

html {
  scroll-behavior: smooth;
  text-rendering: optimizeLegibility;
  overflow-y: scroll;
}

div {
  font-family: "Open Sans", sans-serif;
  font-family: "Poppins", sans-serif;
}

body {
  margin: 0;
  font-weight: 400;
  background: #f8f8f8;
  font-family: "Open Sans", sans-serif;
  font-family: "Poppins", sans-serif;
}

h1,
.h1,
h2,
.h2 {
  margin-top: 0;
  margin-bottom: 0.1rem;
  color: #333333;
  line-height: 150%;
  font-family: "Open Sans", sans-serif;
  font-family: "Poppins", sans-serif;
}

h1 {
  font-size: 32px !important;
  font-weight: 700 !important;
}

h2 {
  font-size: 20px !important;
  font-weight: 600 !important;
  color: #333333;
}

p {
  margin-top: 0;
  line-height: 170%;
  font-size: 400;
}

button:focus,
textarea:focus,
input:focus {
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

a {
  text-decoration: none !important;
  color: unset;
}
a:hover {
  color: #2f4858;
}

ul {
  list-style: none;
  padding: 0;
}

.clearfix:before, .clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}

.form-check-input {
  cursor: pointer;
}

thead {
  background: #3cc13b;
  color: white;
}

th {
  font-weight: 500;
  font-size: 18px;
  padding-top: 0.7rem !important;
  padding-bottom: 0.7rem !important;
}

td {
  border: none !important;
  padding: 0.7rem !important;
  font-size: 14px;
  color: #333333;
}

thead {
  border-style: hidden !important;
}

.strongUp {
  text-transform: uppercase;
  color: #2f4858;
}

.cpr {
  color: #d83f43;
}

.cprt {
  color: #fff0f0;
}

.bpr {
  background: #d83f43;
}

.bprt {
  background: #fff0f0;
}

.cse {
  color: #2f4858;
}

.bse {
  background: #2f4858;
}

.csu {
  color: #3cc13b;
}

.bsu {
  background: #3cc13b;
}

.csut {
  color: #d7f3d7;
}

.bsut {
  background-color: #d7f3d7;
}

.cwa {
  color: #f3bb1c;
}

.bwa {
  background: #f3bb1c;
}

.cwat {
  color: #fdf1d1;
}

.bwat {
  background: #fdf1d1;
}

.cda {
  color: #f03738;
}

.bda {
  background: #f03738;
}

.bdat {
  background: #fcd7d7;
}

.bcdat {
  background: #fcd7d7;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bton {
  font-weight: 400;
  letter-spacing: 0.5px;
  border-radius: 4px;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  font-size: 16px;
  transition: all 0.2s ease-in-out;
}
.bton--sm {
  padding-left: 20px;
  padding-right: 20px;
  line-height: 34px;
}
.bton--md {
  padding-left: 28px;
  padding-right: 28px;
  line-height: 39px;
}
.bton--lg {
  padding-left: 36px;
  padding-right: 36px;
  line-height: 39px;
}
.bton--full {
  line-height: 46px;
  margin: 0;
  width: 100%;
  font-weight: 100;
}
.bton--half {
  line-height: 48px;
  width: 50%;
}
.bton--primary {
  border-color: #d83f43;
  color: white;
  background-color: #d83f43;
}
.bton--primary:hover {
  background-color: #e73138;
  border-color: #e73138;
  color: white;
}
.bton--secondary {
  color: white;
  background-color: #2f4858;
  border-color: #2f4858;
}
.bton--secondary:hover {
  background-color: #2371a1;
  border-color: #2371a1;
  color: white;
}
.bton--ghost {
  border-radius: 4px;
  border-color: #d83f43;
  color: #d83f43;
  background-color: #ffffff;
}
.bton--ghost:hover {
  background-color: #e73138;
  border-color: #e73138;
  color: white;
}
.bton--ghost--danger {
  border-color: #f03738;
  background: #fcd7d7;
  color: #f03738;
}
.bton--ghost--danger:hover {
  background-color: #f03738;
  border-color: #f03738;
  color: white;
}
.bton--danger {
  background: #f03738;
  color: white;
  border: #f03738;
}
.bton--nacked {
  border: none;
  color: rgb(20, 20, 185);
  background: none;
  padding: 0;
}

.disabledButton {
  background: gray !important;
  border-color: grey;
  cursor: not-allowed;
}
.disabledButton:hover {
  border-color: grey;
}

.searchComp-input {
  height: 42px;
  margin-right: 5px;
  width: 25rem;
  border-radius: 4px;
  border: 1px solid #cccccc;
  padding: 1rem;
}

.dateComp .dateInput {
  height: 42px;
  margin-right: 5px;
  border-radius: 4px;
  border: 1px solid #cccccc;
  padding: 1rem;
  color: #4d4d4d;
}

.checkBox {
  border-radius: 4px;
  border: 1px solid #cccccc;
  padding: 0.3rem 1rem;
  color: #4d4d4d;
  display: inline-block;
}

.navWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  padding: 0.3rem 0;
}
.navWrapper__navBrand {
  margin-bottom: 0;
}
.navWrapper__navBrand img {
  width: 8rem;
}
@media all and (max-width: 768px) {
  .navWrapper__navBrand img {
    width: 4rem;
  }
}
.navWrapper__navItems {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
}
.navWrapper__navItems--items {
  padding: 1rem 1.5rem;
  cursor: pointer;
}
@media screen and (max-width: 1040px) {
  .navWrapper__navItems {
    display: none;
  }
}
.navWrapper__mdNav {
  display: none;
}
@media all and (max-width: 1040px) {
  .navWrapper__mdNav {
    display: block;
  }
  .navWrapper__mdNav .open_mdNav {
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 1.8rem;
  }
  .navWrapper__mdNav .mdNav_list_container {
    position: fixed;
    top: 0;
    right: -100%;
    height: 100vh;
    min-height: 600px;
    width: 100%;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.719);
    transition: all 300ms linear;
    display: flex;
    justify-content: flex-end;
  }
  .navWrapper__mdNav .mdNav_list_container .mdNav_list {
    background-color: white;
    width: 25%;
    min-width: 25rem;
    height: 100%;
    padding: 1rem 0.5rem;
  }
  .navWrapper__mdNav .mdNav_list_container .mdNav_list li:first-child {
    display: flex;
    justify-content: space-between;
  }
  .navWrapper__mdNav .mdNav_list_container .mdNav_list li:first-child .close_mdNav_container {
    padding-top: 0.8rem;
  }
  .navWrapper__mdNav .mdNav_list_container .mdNav_list li:first-child .close_mdNav_container .close_mdNav {
    border: none;
    background-color: transparent;
    outline: none;
    font-size: 1.3rem;
  }
  .navWrapper__mdNav .mdNav_list_container .mdNav_list li:not(:first-child) {
    margin-left: 0.5rem;
  }
  .navWrapper__mdNav .mdNav_list_container .mdNav_list li .mdnavBrand img {
    width: 5rem;
    margin-left: 1.5rem;
  }
}
@media all and (max-width: 1040px) and (max-width: 576px) {
  .navWrapper__mdNav .mdNav_list_container .mdNav_list {
    width: 100%;
    min-width: 100%;
  }
}
@media all and (max-width: 1040px) {
  .navWrapper__mdNav .showmdNav {
    right: 0;
  }
}

@media all and (max-width: 1040px) {
  #dropdown-basic {
    padding: 0 !important;
  }
  .dropdown-menu {
    margin-top: 1rem !important;
  }
}
.activeSidebar {
  color: #d83f43;
}

.notActive {
  color: #4d4d4d;
}

section[role=navDrop] .btn-success {
  color: #4d4d4d;
  background-color: #ffffff;
  border: none;
}
section[role=navDrop] .btn-check:active + .btn-success,
section[role=navDrop] .btn-check:checked + .btn-success,
section[role=navDrop] .btn-success.active,
section[role=navDrop] .btn-success:active,
section[role=navDrop] .show > .btn-success.dropdown-toggle {
  color: #4d4d4d;
  background-color: #ffffff;
  border: none;
}

section[role=Footer] {
  margin-top: 8rem;
  background: #2f4858;
}
section[role=Footer] .foooterInfo {
  margin-top: 3rem;
}
section[role=Footer] .foooterInfo .Links-title h2 {
  color: white;
  font-weight: 500 !important;
}
section[role=Footer] .foooterInfo img {
  width: 12rem;
}
section[role=Footer] .foooterInfo p {
  color: white;
  line-height: 180%;
  width: 100%;
  margin-top: 2rem;
}
section[role=Footer] .Links {
  color: white;
}
section[role=Footer] .Links-title {
  color: white;
  margin-top: 3rem;
}
@media all and (max-width: 576px) {
  section[role=Footer] .Links-title {
    margin-top: 2rem;
  }
}
section[role=Footer] .Links-title h2 {
  color: white;
  font-weight: 500 !important;
}
section[role=Footer] .Links-box {
  margin-top: 2rem;
  padding: 0;
}
section[role=Footer] .Links-box-item {
  margin-top: 1rem;
  text-decoration: underline;
  cursor: pointer;
}
section[role=Footer] .Links-box-item a {
  color: white !important;
}
section[role=Footer] .contact {
  color: white;
}
section[role=Footer] .contact-title {
  margin-top: 3rem;
}
@media all and (max-width: 576px) {
  section[role=Footer] .contact-title {
    margin-top: 2rem;
  }
}
section[role=Footer] .contact-title h2 {
  color: white;
  font-weight: 500 !important;
}
section[role=Footer] .contact-box {
  padding: 0;
}
section[role=Footer] .contact-box-item {
  margin-top: 2rem;
  display: flex;
}
section[role=Footer] .contact-box-item .iconHolder {
  margin-right: 1rem;
}
section[role=Footer] .contact-box-item .addHolder-value {
  font-size: 14px;
  font-weight: 100;
  color: white !important;
}
section[role=Footer] .socialMedia-title {
  margin-top: 3rem;
}
@media all and (max-width: 576px) {
  section[role=Footer] .socialMedia-title {
    margin-top: 2rem;
  }
}
section[role=Footer] .socialMedia-title h2 {
  color: white;
  font-weight: 500 !important;
}
section[role=Footer] .socialMedia .socialWrapper {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-top: 2rem;
}
section[role=Footer] .socialMedia .socialWrapper-icons {
  cursor: pointer;
  margin-right: 1rem;
  border: 1px solid white;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all ease-in 0.2s;
}
section[role=Footer] .socialMedia .socialWrapper-icons i {
  color: #f4f4f4;
}
section[role=Footer] .socialMedia .socialWrapper-icons:hover {
  background-color: white;
  color: #2f4858;
}

.copyright {
  padding-bottom: 3rem;
  color: white;
  text-align: center;
}

.loader {
  display: flex;
  justify-content: center;
}

.loader__item {
  width: 16px;
  height: 16px;
  margin: 4px;
  background: rgb(179, 179, 179);
  border-radius: 50%;
  list-style: none;
  -webkit-animation: animation-loading 1.2s infinite;
          animation: animation-loading 1.2s infinite;
}
.loader__item:nth-child(1) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.loader__item:nth-child(2) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}

@-webkit-keyframes animation-loading {
  0% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
  80% {
    transform: scale(0);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes animation-loading {
  0% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
  80% {
    transform: scale(0);
  }
  100% {
    transform: scale(0);
  }
}
.skeleton-box {
  position: relative;
  overflow: hidden;
  background-color: #e2e5e7;
  border-radius: 6px;
}
.skeleton-box::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
  background-size: 100px 100%;
  background-repeat: no-repeat;
  -webkit-animation: shimmer 1s ease infinite;
          animation: shimmer 1s ease infinite;
  content: "";
}
@-webkit-keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

section[role=hero] {
  height: 70vh;
}
@media all and (max-width: 992px) {
  section[role=hero] {
    height: 60vh;
  }
}
@media all and (max-width: 576px) {
  section[role=hero] {
    height: 50vh;
  }
}
section[role=hero] .herocontainer {
  overflow: hidden;
}

.carousel-caption {
  top: 30%;
}
@media all and (max-width: 1200px) {
  .carousel-caption {
    top: 20%;
  }
}
@media all and (max-width: 768px) {
  .carousel-caption {
    top: 13%;
  }
}
@media all and (max-width: 576px) {
  .carousel-caption {
    top: 7%;
  }
}
.carousel-caption .carouselInfo h1 {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 45px !important;
  color: white;
  text-shadow: 1px 2px 3px rgb(32, 29, 29);
}
@media all and (max-width: 1200px) {
  .carousel-caption .carouselInfo h1 {
    font-size: 35px !important;
  }
}
@media all and (max-width: 992px) {
  .carousel-caption .carouselInfo h1 {
    font-size: 26px !important;
    line-height: 120%;
  }
}
@media all and (max-width: 576px) {
  .carousel-caption .carouselInfo h1 {
    font-size: 20px !important;
    line-height: 120%;
  }
}
.carousel-caption .carouselInfo p {
  text-shadow: 1px 2px 3px rgb(32, 29, 29);
  margin: 0 auto;
  font-size: 18px;
  line-height: 160%;
}
@media all and (max-width: 992px) {
  .carousel-caption .carouselInfo p {
    font-size: 16px;
    line-height: 140%;
  }
}
@media all and (max-width: 576px) {
  .carousel-caption .carouselInfo p {
    font-size: 14px;
    line-height: 140%;
  }
}
@media all and (max-width: 480px) {
  .carousel-caption .carouselInfo p {
    font-size: 13px;
    line-height: 130%;
  }
}

.carousel-item {
  position: relative;
}
.carousel-item .heroOverlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  background: linear-gradient(180deg, rgba(245, 70, 12, 0) 19.93%, rgba(126, 37, 7, 0.47) 77.15%);
}

.carousel-control-next, .carousel-control-prev {
  display: none;
}

.carousel-indicators {
  margin-bottom: 2rem;
  bottom: -10px;
}
.carousel-indicators button {
  height: 0.7rem !important;
  width: 0.7rem !important;
  border: 0.1px solid rgba(255, 255, 255, 0) !important;
  overflow: hidden !important;
  border-radius: 0.7rem;
  margin-right: 1rem !important;
}
.carousel-indicators .active {
  width: 1.5rem !important;
}
.carousel-indicators li {
  background: rgb(255, 255, 255);
  height: 8px;
  width: 8px;
  border-top: none;
  border-bottom: none;
  border-radius: 8px;
}
.carousel-indicators li:hover {
  transform: scale(1.3);
}
.carousel-indicators .active {
  transform: scale(1.3);
}

@media all and (max-width: 576px) {
  .careusolHeroBtn {
    display: none;
  }
}

.MobilecareusolHeroBtn {
  display: none;
}
@media all and (max-width: 576px) {
  .MobilecareusolHeroBtn {
    display: block;
  }
}

.careouselImage {
  width: 100%;
}
@media all and (max-width: 1200px) {
  .careouselImage {
    width: auto;
    height: 70vh;
  }
}
@media all and (max-width: 992px) {
  .careouselImage {
    width: auto;
    height: 60vh;
  }
}
@media all and (max-width: 576px) {
  .careouselImage {
    width: auto;
    height: 50vh;
  }
}

.statsWrapper {
  margin-top: 8rem;
}
.statsWrapper-number {
  font-size: 55px;
  font-weight: 700;
  text-decoration: underline;
  -webkit-text-decoration-color: #d83f43;
          text-decoration-color: #d83f43;
  text-decoration-thickness: 0.6rem;
}
.statsWrapper-label {
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 500;
  margin-top: 0.5rem;
}

.headTitle-sub {
  margin-bottom: 1rem;
  color: #d83f43;
  font-size: 18px;
}

.aboutWrapper {
  margin-top: 7rem;
}
.aboutWrapper .aboutContent {
  margin-top: 3rem;
}
.aboutWrapper .aboutContent p {
  line-height: 200%;
  color: #4d4d4d;
}
.aboutWrapper .aboutImage img {
  width: 100%;
  height: 28rem;
  -o-object-fit: contain;
     object-fit: contain;
}

.eventWrapper {
  margin-top: 7rem;
}
.eventWrapper .eventCard {
  border: 1px solid rgb(230, 230, 230);
  margin-top: 2rem;
}
.eventWrapper .eventCard-month {
  background: #d83f43;
  padding: 0.8rem;
  color: white;
  text-align: center;
  font-size: 18px;
}
.eventWrapper .eventCard-day {
  padding-top: 0.5rem;
  color: #333333;
  text-align: center;
  font-size: 50px;
  font-weight: 600;
}
.eventWrapper .eventCard-title {
  padding: 0.8rem;
  font-weight: 400;
  font-size: 15px;
  height: 5.2rem;
  overflow: hidden;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  /* autoprefixer: off */
  -webkit-line-clamp: 3;
}

.charityWrapper {
  margin-top: 7rem;
  background: #2f4858;
  padding-top: 3rem;
  text-align: center;
}
.charityWrapper-title {
  color: white;
}
.charityWrapper-desc {
  color: white;
  margin-top: 2rem;
  width: 60%;
  margin: 1rem auto;
  margin-bottom: 3rem;
}
@media all and (max-width: 576px) {
  .charityWrapper-desc {
    width: 100%;
  }
}
.charityWrapper-image {
  margin-bottom: 3rem;
}
.charityWrapper-image img {
  width: 100%;
  border: 2px solid white;
  height: 26rem;
  -o-object-fit: cover;
     object-fit: cover;
}

.widgetWrapper {
  padding: 1rem 2rem;
  margin-top: 7rem;
  background: white;
  box-shadow: 1px 3px 8px 1px rgba(245, 70, 12, 0.16);
  flex-wrap: wrap;
}
.widgetWrapper h2 {
  font-weight: 500 !important;
  font-size: 24px !important;
  color: #4d4d4d;
}

.first_about_main_container {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url("../assets/images/membershero.png"), url("../assets/images/membershero.png");
  background-size: cover;
  padding: 6rem 0;
}
.first_about_main_container .first_about_container {
  text-align: center;
}
.first_about_main_container .first_about_container .first_about_header {
  font-size: 56px;
  font-weight: 700;
  color: white;
  text-shadow: 1px 3px 6px rgb(31, 31, 31);
  text-transform: uppercase;
}
@media all and (max-width: 576px) {
  .first_about_main_container .first_about_container .first_about_header {
    font-size: 32px;
  }
}
.first_about_main_container .first_about_container .first_about_second_header {
  font-size: 32px;
  font-weight: 700;
  color: white;
}
.first_about_main_container .first_about_container .first_about_second_header .ls_about {
  font-size: 35px;
  color: #d83f43;
}
.first_about_main_container .first_about_container .first_about_desc {
  margin-top: 2rem;
  font-size: 18px;
  color: white;
}

.membersWrapper {
  margin-top: 5rem;
}
.membersWrapper .searchMember {
  margin-top: 3rem;
  margin-bottom: 1rem;
}
.membersWrapper .searchMember input {
  padding: 0.5rem;
  border: 1px solid rgb(158, 158, 158);
  border-radius: 4px;
  width: 30%;
}
@media all and (max-width: 576px) {
  .membersWrapper .searchMember input {
    width: 100%;
  }
}

.membercard {
  margin-top: 2rem;
  background: white;
  box-shadow: 1px 3px 8px 1px rgba(78, 78, 78, 0.16);
  padding: 1.5rem 1rem;
  min-height: 14rem;
  transition: transform 0.15s ease-in;
}
.membercard:hover {
  transform: scale(1.05);
}
.membercard img {
  width: 100%;
  margin-top: 2rem;
  height: 4rem;
  -o-object-fit: contain;
     object-fit: contain;
}
.membercard figcaption {
  margin-top: 2rem;
  text-align: center;
  color: #4d4d4d;
  height: 3.2rem;
  overflow: hidden;
}

.interestedWrapper {
  margin-top: 5rem;
  background: #d83f43;
  display: flex;
}
.interestedWrapper-image {
  width: 50%;
  margin: 0;
  position: relative;
}
@media all and (max-width: 768px) {
  .interestedWrapper-image {
    width: 100%;
  }
}
.interestedWrapper-image img {
  width: 100%;
}
.interestedWrapper-image-red {
  height: 100%;
}
@media all and (max-width: 768px) {
  .interestedWrapper-image-red {
    display: none;
  }
}
.interestedWrapper-image-desc {
  position: absolute;
  top: 15%;
  padding: 2rem;
  right: -70%;
  border: 2px solid white;
  width: 50rem;
  background: rgba(47, 72, 88, 0.92);
}
@media all and (max-width: 768px) {
  .interestedWrapper-image-desc {
    width: auto;
    position: static;
  }
}
.interestedWrapper-image-desc h1 {
  color: white !important;
  text-transform: uppercase;
}
.interestedWrapper-image-desc p {
  color: white;
  line-height: 200%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-weight: 300;
}

.applicationHolder {
  margin-top: 2rem;
  border: 1px solid rgb(230, 230, 230);
  padding: 1rem;
}
.applicationHolder h2 {
  margin-bottom: 2rem;
  margin-top: 2rem;
  text-align: center;
}

.noImage {
  text-transform: uppercase;
  display: grid;
  place-items: center;
  width: 100%;
  font-size: 4rem;
  font-weight: 600;
  background: #fff0f0;
  color: #d83f43;
}

.formWrapper {
  padding: 1rem;
  margin-top: 2rem;
}
.formWrapper p {
  color: #4d4d4d;
  font-size: 14px;
}

.feedbackSection {
  text-align: center;
}
.feedbackSection-success {
  background: #d7f3d7;
  color: #3cc13b;
  font-size: 18px;
  padding: 6px;
  border-radius: 6px;
  box-shadow: 1px 1px 6px rgba(226, 226, 226, 0.904);
  margin-top: 1rem;
  -webkit-animation: example2 0.3s ease-in-out;
          animation: example2 0.3s ease-in-out;
}
.feedbackSection-failure {
  background: #fcd7d7;
  color: #f03738;
  font-size: 18px;
  padding: 6px;
  border-radius: 6px;
  box-shadow: 1px 1px 6px rgba(226, 226, 226, 0.904);
  margin-top: 1rem;
  -webkit-animation: example2 0.3s ease-in-out;
          animation: example2 0.3s ease-in-out;
}

@-webkit-keyframes example2 {
  from {
    transform: translatey(10px);
    opacity: 0;
  }
  to {
    transform: translatey(0);
    opacity: 1;
  }
}

@keyframes example2 {
  from {
    transform: translatey(10px);
    opacity: 0;
  }
  to {
    transform: translatey(0);
    opacity: 1;
  }
}
.contactInfo {
  margin-top: 5rem;
  border: 1px solid rgb(230, 230, 230);
  border-radius: 6px;
  padding: 1rem;
}/*# sourceMappingURL=App.css.map */