section[role='hero'] {
  // background: rgb(243, 243, 243);
  height: 70vh;
  // background: #000;

  //   @include responsive-for(xlg) {
  //     height: 70vh;
  //   }

  @include responsive-for(lg) {
    height: 60vh;
  }
  @include responsive-for(sm) {
    height: 50vh;
  }

  .herocontainer {
    overflow: hidden;
  }
}

//carousel part
.carousel-caption {
  // bottom: 25%;
  top: 30%;
  @include responsive-for(xlg) {
    top: 20%;
  }
  @include responsive-for(md) {
    top: 13%;
  }
  @include responsive-for(sm) {
    top: 7%;
  }
  // @include responsive-for(lg) {
  //   top: 15%;
  // }
  // @include responsive-for(sm) {
  // }
  // @include responsive-for(xs) {
  //   padding-top: 0.2rem;
  // }
  .carouselInfo {
    h1 {
      font-weight: 600;
      text-transform: uppercase;
      font-size: 45px !important;
      color: white;
      text-shadow: 1px 2px 3px rgb(32, 29, 29);

      @include responsive-for(xlg) {
        font-size: 35px !important;
      }
      @include responsive-for(lg) {
        font-size: 26px !important;
        line-height: 120%;
      }
      @include responsive-for(sm) {
        font-size: 20px !important;
        line-height: 120%;
      }
    }
    p {
      text-shadow: 1px 2px 3px rgb(32, 29, 29);
      // width: 50vw;
      margin: 0 auto;
      font-size: 18px;
      line-height: 160%;
      @include responsive-for(lg) {
        font-size: 16px;
        line-height: 140%;
      }
      @include responsive-for(sm) {
        font-size: 14px;
        line-height: 140%;
      }
      @include responsive-for(xs) {
        font-size: 13px;
        line-height: 130%;
      }
    }
  }
}

.carousel-item {
  position: relative;
  .heroOverlay {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    background: linear-gradient(
      180deg,
      rgba(245, 70, 12, 0) 19.93%,
      rgba(126, 37, 7, 0.47) 77.15%
    );
  }

  // @include responsive-for(sm) {
  //   width: auto;
  // }
}

//next prev buttons
.carousel-control {
  &-next,
  &-prev {
    display: none;
  }
}

.carousel-indicators {
  margin-bottom: 2rem;
  bottom: -10px;
  button {
    height: 0.7rem !important;
    width: 0.7rem !important;
    border: 0.1px solid rgba(255, 255, 255, 0) !important;
    overflow: hidden !important;
    border-radius: calc(0.7rem);
    margin-right: 1rem !important;
  }

  .active {
    width: 1.5rem !important;
  }

  li {
    background: rgb(255, 255, 255);
    height: 8px;
    width: 8px;
    border-top: none;
    border-bottom: none;
    border-radius: calc(8px);
    &:hover {
      transform: scale(1.3);
    }
  }
  .active {
    transform: scale(1.3);
  }
}

.careusolHeroBtn {
  @include responsive-for(sm) {
    display: none;
  }
}

.MobilecareusolHeroBtn {
  display: none;
  @include responsive-for(sm) {
    display: block;
  }
}

.careouselImage {
  width: 100%;

  @include responsive-for(xlg) {
    width: auto;
    height: 70vh;
  }
  @include responsive-for(lg) {
    width: auto;
    height: 60vh;
  }
  @include responsive-for(sm) {
    width: auto;
    height: 50vh;
  }
}

//stats part

.statsWrapper {
  margin-top: 8rem;
  &-number {
    font-size: 55px;
    font-weight: 700;
    text-decoration: underline;
    text-decoration-color: color(primary, base);
    text-decoration-thickness: 0.6rem;
  }
  &-label {
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 500;
    margin-top: 0.5rem;
  }
}

.headTitle {
  &-sub {
    margin-bottom: 1rem;
    color: color(primary, base);
    font-size: 18px;
  }
}
//about Section

.aboutWrapper {
  margin-top: 7rem;

  .aboutContent {
    margin-top: 3rem;
    p {
      line-height: 200%;
      color: color(text-light);
    }
  }

  .aboutImage {
    img {
      width: 100%;
      height: 28rem;
      object-fit: contain;
    }
  }
}

//event side

.eventWrapper {
  margin-top: 7rem;

  .eventCard {
    border: $border;
    margin-top: 2rem;
    &-month {
      background: color(primary, base);
      padding: 0.8rem;
      color: white;
      text-align: center;
      font-size: 18px;
    }
    &-day {
      padding-top: 0.5rem;
      color: color(text-dark);
      text-align: center;
      font-size: 50px;
      font-weight: 600;
    }
    &-title {
      padding: 0.8rem;
      font-weight: 400;
      font-size: 15px;
      height: 5.2rem;
      overflow: hidden;
      @include truncate;
      -webkit-line-clamp: 3;
    }
  }
}

.charityWrapper {
  margin-top: 7rem;
  background: color(secondary, base);
  padding-top: 3rem;
  text-align: center;
  &-title {
    color: white;
  }
  &-desc {
    color: white;
    margin-top: 2rem;
    width: 60%;
    margin: 1rem auto;
    margin-bottom: 3rem;
    @include responsive-for(sm) {
      width: 100%;
    }
  }

  &-image {
    margin-bottom: 3rem;
    img {
      width: 100%;
      border: 2px solid white;
      height: 26rem;
      object-fit: cover;
    }
  }
}

//widget part

.widgetWrapper {
  padding: 1rem 2rem;
  margin-top: 7rem;
  background: white;
  box-shadow: 1px 3px 8px 1px rgba(245, 70, 12, 0.16);
  flex-wrap: wrap;
  h2 {
    font-weight: 500 !important;
    font-size: 24px !important;
    color: color(text-light);
  }
}
