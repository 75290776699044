.first_about_main_container {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    url('../assets/images/membershero.png'),
    url('../assets/images/membershero.png');
  background-size: cover;
  padding: 6rem 0;
  .first_about_container {
    text-align: center;
    .first_about_header {
      font-size: 56px;
      font-weight: $font-bold;
      color: white;
      text-shadow: 1px 3px 6px rgb(31, 31, 31);
      text-transform: uppercase;
      @include responsive-for(sm) {
        font-size: 32px;
      }
    }
    .first_about_second_header {
      font-size: 32px;
      font-weight: $font-bold;
      color: white;
      .ls_about {
        font-size: 35px;
        color: color(primary, base);
      }
    }
    .first_about_desc {
      margin-top: 2rem;
      font-size: $text-lg;
      color: white;
    }
    // .first_about_btn {
    //   margin-top: 3.5rem;
    //   button {
    //     @include btnPrimary;
    //     width: 189px !important;
    //     &:hover::after {
    //       width: 189px !important;
    //     }
    //   }
    // }
  }
}

.membersWrapper {
  margin-top: 5rem;
  .searchMember {
    margin-top: 3rem;
    margin-bottom: 1rem;
    input {
      padding: 0.5rem;
      border: 1px solid rgb(158, 158, 158);
      border-radius: 4px;
      width: 30%;
      @include responsive-for(sm) {
        width: 100%;
      }
    }
  }
}
.membercard {
  margin-top: 2rem;
  background: white;
  box-shadow: 1px 3px 8px 1px rgba(78, 78, 78, 0.16);
  padding: 1.5rem 1rem;
  min-height: 14rem;
  transition: transform 0.15s ease-in;

  &:hover {
    transform: scale(1.05);
  }

  img {
    width: 100%;
    margin-top: 2rem;
    height: 4rem;
    object-fit: contain;
  }
  figcaption {
    margin-top: 2rem;
    text-align: center;
    color: color(text-light);
    height: 3.2rem;
    overflow: hidden;
  }
}

//@at-root

.interestedWrapper {
  margin-top: 5rem;
  background: color(primary, base);
  display: flex;
  // @include responsive-for(md) {
  //   margin-bottom: 20rem;
  // }
  &-image {
    width: 50%;
    margin: 0;
    position: relative;
    @include responsive-for(md) {
      width: 100%;
    }
    img {
      width: 100%;
    }

    &-red {
      height: 100%;
      @include responsive-for(md) {
        display: none;
      }
    }

    &-desc {
      position: absolute;
      top: 15%;
      padding: 2rem;
      right: -70%;
      border: 2px solid white;
      width: 50rem;
      background: rgba(47, 72, 88, 0.92);
      @include responsive-for(md) {
        width: auto;
        position: static;
      }
      h1 {
        color: white !important;
        text-transform: uppercase;
      }
      p {
        color: white;
        line-height: 200%;
        margin-top: 2rem;
        margin-bottom: 2rem;
        font-weight: 300;
      }
    }
  }
}

//membership form

.applicationHolder {
  margin-top: 2rem;
  border: $border;
  padding: 1rem;
  h2 {
    margin-bottom: 2rem;
    margin-top: 2rem;
    text-align: center;
  }
}

.noImage {
  text-transform: uppercase;
  display: grid;
  place-items: center;
  width: 100%;
  font-size: 4rem;
  font-weight: 600;
  background: color(primary, tint);
  color: color(primary, base);
}
