.formWrapper {
  padding: 1rem;
  margin-top: 2rem;
  p {
    color: color(text-light);
    font-size: 14px;
  }
}

.feedbackSection {
  text-align: center;
  &-success {
    background: color(success, tint);
    color: color(success, base);
    font-size: 18px;
    padding: 6px;
    border-radius: $radius;
    box-shadow: 1px 1px 6px rgba(226, 226, 226, 0.904);
    margin-top: 1rem;
    animation: example2 0.3s ease-in-out;
  }
  &-failure {
    background: color(danger, tint);
    color: color(danger, base);
    font-size: 18px;
    padding: 6px;
    border-radius: $radius;
    box-shadow: 1px 1px 6px rgba(226, 226, 226, 0.904);
    margin-top: 1rem;
    animation: example2 0.3s ease-in-out;
  }
}

@keyframes example2 {
  from {
    transform: translatey(10px);
    opacity: 0;
  }
  to {
    transform: translatey(0);
    opacity: 1;
  }
}

.contactInfo {
  margin-top: 5rem;
  border: $border;
  border-radius: $radius;
  padding: 1rem;
}
